import { template as template_fac1fe7e8cf34daf90a8c4481a1947cd } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_fac1fe7e8cf34daf90a8c4481a1947cd(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
