import { template as template_bb1fa62f10ae4c7a85311b6c28444773 } from "@ember/template-compiler";
const FKControlMenuContainer = template_bb1fa62f10ae4c7a85311b6c28444773(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
