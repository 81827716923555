import { template as template_8315da25823f4d97b0a948380baccf34 } from "@ember/template-compiler";
const FKText = template_8315da25823f4d97b0a948380baccf34(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
