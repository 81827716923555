import { template as template_08be0051cfb348fb9f708d5f2e750bcf } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_08be0051cfb348fb9f708d5f2e750bcf(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
